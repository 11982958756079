import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RealEstateRequestApi } from '../api';
import { InspectionMemberNotes, RealEstateRequestSubmitInput, Request, RequestRes, RequestsFilterInput, RequestsList, ShariaMemberNotes, TechnicalMemberNotes, SpecializationMemberNotes } from '../model';
import { Repository } from './repository';
import type { PagedResultDto } from '@abp/ng.core';
import { AllRequestsForExcelInput, RequestFileContentResult, InspectionSupervisorNotes, ModifyRequestDependOnStatementInput } from '../model/real-estate-request';
import { AppAuthService } from '@spga-shared/providers/auth';
import { downloadBlobFile } from '@spga-shared/utils';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestRepository implements Repository<RequestsList, void, Request, RequestsFilterInput> {
  public realEstateRequests$: BehaviorSubject<RequestsList[]> = new BehaviorSubject<RequestsList[]>([]);
  selectedStatementId?: number;

  constructor(
    private realEstateRequestApi: RealEstateRequestApi,
    private auth: AppAuthService
  ) {
  }

  getList(input: RequestsFilterInput): Observable<PagedResultDto<RequestsList>> {
    return this.realEstateRequestApi.getRealEstateRequests(input).pipe(
      tap((res) => {
        this.realEstateRequests$.next(res.items!);
      })
    );
  }

  getDetails(id: number): Observable<Request> {
    return this.realEstateRequestApi.getRealEstateRequest(id);
  }
  getAttachmentDataForRequestSummary(id: number): Observable<RequestFileContentResult> {
    return this.realEstateRequestApi.getAttachmentDataForRequestSummary(id);
  }
  submit(input: RealEstateRequestSubmitInput): Observable<RequestRes> {
    if (this.selectedStatementId) {
      input.statementId = this.selectedStatementId;
    }
    return this.realEstateRequestApi.submitRealEstateRequest(input)
      .pipe(tap(() => this.selectedStatementId = undefined));
  }
  modifyRequestDependOnStatement(input: ModifyRequestDependOnStatementInput): Observable<any> {
    return this.realEstateRequestApi.modifyRequestDependOnStatement(input)
  }

  accept(requestId: number): Observable<RequestRes> {
    return this.realEstateRequestApi.acceptRealEstateRequest(this.auth.currentLoggedInUser$.userName, requestId);
  }

  getRequestsToExcel(input: AllRequestsForExcelInput): Observable<RequestFileContentResult> {
    return this.realEstateRequestApi.getRequestsToExcel(input)
      .pipe(tap(x => downloadBlobFile(x, 'application/xlsx', 'real-estate-requests.xlsx')));
  }

  completeRequestAsInspectionMember(input: InspectionMemberNotes): Observable<void> {
    return this.realEstateRequestApi.completeRequestAsInspectionMember(input);
  }
  completeRequestAsInspectionSupervisor(input: InspectionSupervisorNotes): Observable<void> {
    return this.realEstateRequestApi.completeRequestAsInspectionSupervisor(input);
  }

  completeRequestAsShariaMemberByInput(input: ShariaMemberNotes): Observable<void> {
    return this.realEstateRequestApi.completeRequestAsShariaMemberByInput(input);
  }

  completeRequestAsTechnicalMemberByInput(input: TechnicalMemberNotes): Observable<void> {
    return this.realEstateRequestApi.completeRequestAsTechnicalMemberByInput(input);
  }

  saveRequestAsInspectionMember(input: InspectionMemberNotes): Observable<void> {
    return this.realEstateRequestApi.saveRequestAsInspectionMember(input);
  }
  saveRequestAsInspectionSupervisor(input: InspectionSupervisorNotes): Observable<void> {
    return this.realEstateRequestApi.saveRequestAsInspectionSupervisor(input);
  }

  saveRequestAsShariaMemberByInput(input: ShariaMemberNotes): Observable<void> {
    return this.realEstateRequestApi.saveRequestAsShariaMemberByInput(input);
  }

  saveRequestAsTechnicalMemberByInput(input: TechnicalMemberNotes): Observable<void> {
    return this.realEstateRequestApi.saveRequestAsTechnicalMemberByInput(input);
  }

  completeRequestAsSpecializationMemberByInput(input: SpecializationMemberNotes): Observable<void> {
    return this.realEstateRequestApi.completeRequestAsSpecializationMember(input);
  }
  saveRequestAsSpecializationMember(input: SpecializationMemberNotes): Observable<void> {
    return this.realEstateRequestApi.saveRequestAsSpecializationMember(input);
  }

  getInspectionNotesByRequestId(requestId: number): Observable<InspectionMemberNotes> {
    return this.realEstateRequestApi.getInspectionNotesByRequestId(requestId);
  }
  getShariaNotesByRequestId(requestId: number): Observable<ShariaMemberNotes> {
    return this.realEstateRequestApi.getShariaNotesByRequestId(requestId);
  }
  getTechnicalNotesByRequestId(requestId: number): Observable<TechnicalMemberNotes> {
    return this.realEstateRequestApi.getTechnicalNotesByRequestId(requestId);
  }
  getSpecializationMemberNotesByRequestId(requestId: number): Observable<SpecializationMemberNotes> {
    return this.realEstateRequestApi.getSpecializationMemberNotesByRequestId(requestId);
  }
}
