import type { AreaReportCoordinatesDto, GetAllRequestsForExcelInput, 
  GetAllRequestsInputDto, InspectionMemberAttachmentResponse, 
  InspectionMemberNotesDto, InspectionMemberSavedNotesDto, 
  InspectionSupervisorNotesDto, InspectionSupervisorSavedNotesDto, 
  RealEstateRequestSubmitDto, RequestAreaReportDetailsDto, 
  RequestAreaReportDto, RequestDto, RequestOwnershipDTO, 
  RequestRealEstateEndorsement, RequestReportAttachmentResponse, 
  RequestReportResponse, RequestResponse, RequestsListDto, 
  ShariaMemberAttachmentResponse, ShariaMemberNotesDto, 
  ShariaMemberSavedNotesDto, SpecializationMemberAttachmentResponse, 
  TaqniaAreaReportDto, TechnicalMemberAttachmentResponse,
  TechnicalMemberNotesDto, TechnicalMemberSavedNotesDto,
  UpdateAreaReportCoordinatesDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AttachmentType } from '../enums/attachment-type.enum';
import type { IFormFile } from '../microsoft/asp-net-core/http/models';
import type { FileContentResult } from '../microsoft/asp-net-core/mvc/models';
import type { RequestRealEstateAttachmentDto, RequestRealEstateReportAttachmentDto } from '../real-estate-attachments/models';
import type { RequestRealestateDTO } from '../real-estates/models';
import type { SpecializationMemberNotesDto } from '../specialization-member/models';
import { ModifyRequestDependOnStatementInput } from '@data/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RealEstateOwnershipRequestService {
  apiName = 'Default';

  cancelRequest = (requestId: number) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/cancelRequest/${requestId}`,
    },
    { apiName: this.apiName });

  completeRequestAsInspectionMemberByInput = (input: InspectionMemberNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/completeRequestAsInspectionMember`,
      body: input,
    },
    { apiName: this.apiName });

  completeRequestAsInspectionSupervisorByInput = (input: InspectionSupervisorNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/completeRequestAsInspectionSupervisor`,
      body: input,
    },
    { apiName: this.apiName });

  completeRequestAsShariaMemberByInput = (input: ShariaMemberNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/completeRequestAsShariaMember`,
      body: input,
    },
    { apiName: this.apiName });

  completeRequestAsSpecializationMemberByInput = (input: SpecializationMemberNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/completeRequestAsSpecializationMember`,
      body: input,
    },
    { apiName: this.apiName });
    
    saveRequestAsSpecializationMember = (input: SpecializationMemberNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/saveRequestAsSpecializationMember`,
      body: input,
    },
    { apiName: this.apiName });

  completeRequestAsTechnicalMemberByInput = (input: TechnicalMemberNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/completeRequestAsTechnicalMember`,
      body: input,
    },
    { apiName: this.apiName });

  createAreaReportAttachmentByAttachmentDto = (attachmentDto: RequestRealEstateReportAttachmentDto) =>
    this.restService.request<any, RequestReportAttachmentResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/areaReportAttachment`,
      body: attachmentDto,
    },
    { apiName: this.apiName });

  createAreaReportAttachmentFormFileByFile = (file: IFormFile) =>
    this.restService.request<any, RequestReportAttachmentResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/areaReportAttachmentFormFile`,
    },
    { apiName: this.apiName });

  createInspectionMemberAttachmentByFile = (file: IFormFile) =>
    this.restService.request<any, InspectionMemberAttachmentResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/inspectionMemberAttachment`,
    },
    { apiName: this.apiName });

  createRealEstateRequestAttachmentByAttachmentDto = (attachmentDto: RequestRealEstateAttachmentDto) =>
    this.restService.request<any, RequestResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestAttachment`,
      body: attachmentDto,
    },
    { apiName: this.apiName });

  createRealEstateRequestAttachmentFormFileByFileAndRequestIdAndAttachmentTypeAndAttachmentDateAndDescription = (file: IFormFile, requestId: number, attachmentType: AttachmentType, attachmentDate: string, description?: string) =>
    this.restService.request<any, RequestResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestAttachmentFormFile/${requestId}`,
      params: { attachmentType: attachmentType, attachmentDate: attachmentDate, description: description },
    },
    { apiName: this.apiName });

  createRealEstateRequestEndorsementByInput = (input: RequestRealEstateEndorsement) =>
    this.restService.request<any, RequestResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestEndorsement`,
      body: input,
    },
    { apiName: this.apiName });

  createRealEstateRequestLocationByInput = (input: RequestRealestateDTO) =>
    this.restService.request<any, RequestResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestLocation`,
      body: input,
    },
    { apiName: this.apiName });

  createRealEstateRequestOwnershipByRequest = (request: RequestOwnershipDTO) =>
    this.restService.request<any, RequestResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestOwnership`,
      body: request,
    },
    { apiName: this.apiName });

  createRequestReport = (requestReport: RequestAreaReportDto) =>
    this.restService.request<any, RequestReportResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/requestReport`,
      body: requestReport,
    },
    { apiName: this.apiName });

  createShariaMemberAttachmentByFile = (file: IFormFile) =>
    this.restService.request<any, ShariaMemberAttachmentResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/shariaMemberAttachment`,
    },
    { apiName: this.apiName });

  createSpecializationMemberAttachmentByFile = (file: IFormFile) =>
    this.restService.request<any, SpecializationMemberAttachmentResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/specializationMemberAttachment`,
    },
    { apiName: this.apiName });

  createTechnicalMemberAttachmentByFile = (file: IFormFile) =>
    this.restService.request<any, TechnicalMemberAttachmentResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/technicalMemberAttachment`,
    },
    { apiName: this.apiName });

  deleteRealEstateAttachment = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/realEstateOwnershipRequest/${id}/realEstateAttachment`,
    },
    { apiName: this.apiName });

  downloadAreaReportAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/${id}/downloadAreaReportAttachment`,
    },
    { apiName: this.apiName });

  downloadArealReportAttachmentByRequestId = (requestId: number) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/downloadArealReportAttachment/${requestId}`,
    },
    { apiName: this.apiName });

  downloadInspectionAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/${id}/downloadInspectionAttachment`,
    },
    { apiName: this.apiName });

  downloadRealEstateAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/${id}/downloadRealEstateAttachment`,
    },
    { apiName: this.apiName });

  downloadRealestateLimitsAttachmentByRequestId = (requestId: number) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/downloadRealestateLimitsAttachment/${requestId}`,
    },
    { apiName: this.apiName });

  downloadShariaAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/${id}/downloadShariaAttachment`,
    },
    { apiName: this.apiName });

  downloadTechnicalAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/${id}/downloadTechnicalAttachment`,
    },
    { apiName: this.apiName });

  getAreaReportCoordinatesByRequestId = (requestId: number) =>
    this.restService.request<any, AreaReportCoordinatesDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/areaReportCoordinates/${requestId}`,
    },
    { apiName: this.apiName });

  getInspectionNotesByRequestId = (requestId: number) =>
    this.restService.request<any, InspectionMemberNotesDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/inspectionNotes/${requestId}`,
    },
    { apiName: this.apiName });

  getRealEstateRequestAttachment = (requestId: number) =>
    this.restService.request<any, RequestDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestAttachment/${requestId}`,
    },
    { apiName: this.apiName });

  getRealEstateRequestLocation = (requestId: number) =>
    this.restService.request<any, RequestDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestLocation/${requestId}`,
    },
    { apiName: this.apiName });

  getRealEstateRequestOwnership = (requestId: number) =>
    this.restService.request<any, RequestDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestOwnership/${requestId}`,
    },
    { apiName: this.apiName });

  getRealEstateRequestSummary = (requestId: number) =>
    this.restService.request<any, RequestDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestSummary/${requestId}`,
    },
      { apiName: this.apiName });


  getAttachmentDataForRequestSummary(requestId: number): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/attachmentDataForRequestSummary/${requestId}`,
      responseType: 'blob'
    },
      { apiName: this.apiName });
  }

  getRequestAreaReportDetails = (requestId: number) =>
    this.restService.request<any, RequestAreaReportDetailsDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/requestAreaReportDetails/${requestId}`,
    },
    { apiName: this.apiName });

  getRequestReport = (requestId: number) =>
    this.restService.request<any, RequestAreaReportDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/requestReport/${requestId}`,
    },
    { apiName: this.apiName }); 

  getRequetsList = (input: GetAllRequestsInputDto) =>
    this.restService.request<any, PagedResultDto<RequestsListDto>>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/requetsList`,
      params: { completeShariaRequestsFilter:input.completeShariaRequestsFilter,
        completeTechnicalRequestsFilter:input.completeTechnicalRequestsFilter,
        publishedByFilter: input.publishedByFilter,
        engOfficeStatementInternalRequestStatusFilter: input.engOfficeStatementInternalRequestStatusFilter,
        isBeneficiaryStatementAnswered: input.isBeneficiaryStatementAnswered, 
        isEngOfficeStatementAnswered: input.isEngOfficeStatementAnswered, 
        filter: input.filter, 
        requestTypeFilter: input.requestTypeFilter, 
        selectedRequestTypeFilter: input.selectedRequestTypeFilter, 
        regionFilter: input.regionFilter, 
        selectedRegionFilter: input.selectedRegionFilter, 
        cityFilter: input.cityFilter, 
        selectedCityFilter: input.selectedCityFilter, 
        statusFilter: input.statusFilter, 
        dateFromFilter: input.dateFromFilter, 
        dateToFilter: input.dateToFilter, 
        recommendationDateFromFilter: input.recommendationDateFromFilter, 
        recommendationDateToFilter: input.recommendationDateToFilter, 
        isTasks: input.isTasks, 
        realEstateTypeFilter: input.realEstateTypeFilter, 
        selectedRealEstateTypeFilter: input.selectedRealEstateTypeFilter, 
        statementType: input.statementType, 
        auditRequestTypeFilter: input.auditRequestTypeFilter, 
        supervisorRequestTypeFilter: input.supervisorRequestTypeFilter, 
        preliminaryStudiesCommitteeSupervisorFilter: input.preliminaryStudiesCommitteeSupervisorFilter, 
        statementStatusFilter: input.statementStatusFilter, 
        statementStatusWithExpiredFilter: input.statementStatusWithExpiredFilter, 
        technicalStatementTypeFilter: input.technicalStatementTypeFilter, 
        employeeNameFilter: input.employeeNameFilter, 
        requestPathFilter: input.requestPathFilter, 
        preliminaryStudiesCommitteeSupervisorApprovedDateFrom: input.preliminaryStudiesCommitteeSupervisorApprovedDateFrom, 
        preliminaryStudiesCommitteeSupervisorApprovedDateTo: input.preliminaryStudiesCommitteeSupervisorApprovedDateTo, 
        requestsStudyingMemberApprovedDateFrom: input.requestsStudyingMemberApprovedDateFrom, 
        requestsStudyingMemberApprovedDateTo: input.requestsStudyingMemberApprovedDateTo, 
        requestsFormalGovRecordDateFrom: input.requestsFormalGovRecordDateFrom, 
        requestsFormalGovRecordDateTo: input.requestsFormalGovRecordDateTo, 
        specRequestStatusFilter: input.specRequestStatusFilter, 
        isFormalRequestPath: input.isFormalRequestPath, 
        isGovernmentalRequestPath: input.isGovernmentalRequestPath, 
        sadadDateFromFilter: input.sadadDateFromFilter, 
        sadadDateToFilter: input.sadadDateToFilter, 
        delayRequestDateFromFilter: input.delayRequestDateFromFilter, 
        delayRequestDateToFilter: input.delayRequestDateToFilter, 
        auditReassignRequestDateFromFilter: input.auditReassignRequestDateFromFilter, 
        auditReassignRequestDateToFilter: input.auditReassignRequestDateToFilter, 
        internalRequestStatusFilter: input.internalRequestStatusFilter,
        adminEngOfficeStatementInternalRequestStatusFilter: input.adminEngOfficeStatementInternalRequestStatusFilter, 
        requestAreaFilter: input.requestAreaFilter, 
        realEstateRevivalFilter: input.realEstateRevivalFilter, 
        selectedRevivalTypeFilter: input.selectedRevivalTypeFilter, 
        SelectedRevivalEvidenceFilter: input.selectedRevivalEvidenceFilter, 
        selectedRevivalDateFilter: input.selectedRevivalDateFilter, 
        endorsementStatusFilter: input.endorsementStatusFilter,
        archiveDateFromFilter: input.archiveDateFromFilter,
        archiveDateToFilter: input.archiveDateToFilter,
        objectionStatusFilter:input.objectionStatusFilter, 
        sorting: input.sorting, skipCount: input.skipCount, 
        maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getRequetsToExcel = (input: GetAllRequestsForExcelInput) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/requetsToExcel`,
      params: { filter: input.filter, requestTypeFilter: input.requestTypeFilter, regionFilter: input.regionFilter, cityFilter: input.cityFilter, statusFilter: input.statusFilter, dateFromFilter: input.dateFromFilter, dateToFilter: input.dateToFilter },
    },
    { apiName: this.apiName });

  getShariaNotesByRequestId = (requestId: number) =>
    this.restService.request<any, ShariaMemberNotesDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/shariaNotes/${requestId}`,
    },
    { apiName: this.apiName });

  getTechnicalNotesByRequestId = (requestId: number) =>
    this.restService.request<any, TechnicalMemberNotesDto>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/technicalNotes/${requestId}`,
    },
    { apiName: this.apiName });

  isCreateNewRequestEnabled = () =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequest/isCreateNewRequestEnabled`,
    },
    { apiName: this.apiName });

  realEstateRequestSubmitByInput = (input: RealEstateRequestSubmitDto) =>
    this.restService.request<any, RequestResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/realEstateRequestSubmit`,
      body: input,
    },
    { apiName: this.apiName });

  modifyRequestDependOnStatement = (input: ModifyRequestDependOnStatementInput) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/modifyRequestDependOnStatement`,
      body: input,
    },
    { apiName: this.apiName });

  requestAcceptByUserNameAndRequestId = (userName: string, requestId: number) =>
    this.restService.request<any, RequestResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/requestAccept/${requestId}`,
      params: { userName: userName },
    },
    { apiName: this.apiName });

  saveRequestAsInspectionMemberByInput = (input: InspectionMemberSavedNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/saveRequestAsInspectionMember`,
      body: input,
    },
    { apiName: this.apiName });

  saveRequestAsInspectionSupervisorByInput = (input: InspectionSupervisorSavedNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/saveRequestAsInspectionSupervisor`,
      body: input,
    },
    { apiName: this.apiName });

  saveRequestAsShariaMemberByInput = (input: ShariaMemberSavedNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/saveRequestAsShariaMember`,
      body: input,
    },
    { apiName: this.apiName });

  saveRequestAsTechnicalMemberByInput = (input: TechnicalMemberSavedNotesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/saveRequestAsTechnicalMember`,
      body: input,
    },
    { apiName: this.apiName });

  updateAreaReportCoordinatesByInput = (input: UpdateAreaReportCoordinatesDto) =>
    this.restService.request<any, boolean>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequest/areaReportCoordinates`,
      body: input,
    },
    { apiName: this.apiName });

    checkTaqniaToolData = (taqniaAreaReportDto: TaqniaAreaReportDto) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequest/checkTaqniaToolData`,
      body: taqniaAreaReportDto,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
