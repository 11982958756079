import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { InspectionMemberNotes, RealEstateRequestSubmitInput, Request, RequestRes, RequestsFilterInput, RequestsList, ShariaMemberNotes, TechnicalMemberNotes, SpecializationMemberNotes} from '../model';
import { RealEstateOwnershipRequestService } from '@proxy/real-estate-ownership-requests/real-estate-ownership-request.service';
import type { PagedResultDto } from '@abp/ng.core';
import { RestService } from '@abp/ng.core';
import { AllRequestsForExcelInput, RequestFileContentResult, InspectionSupervisorNotes, ModifyRequestDependOnStatementInput } from '../model/real-estate-request';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { SpecializationMemberService } from '@proxy/specialization-member/specialization-member.service';
import { downloadBlobFile } from '@spga-shared/utils';
@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestApi {

  constructor(
    private realEstateOwnershipRequestService: RealEstateOwnershipRequestService,
    private restService: RestService,
    private specializationMemberService:SpecializationMemberService
  ) {
  }

  getRealEstateRequest(requestId: number): Observable<Request> {
    return this.realEstateOwnershipRequestService.getRealEstateRequestSummary(requestId).pipe(
      map((res) => {  
        return res;
      })
    );
  }

  getAttachmentDataForRequestSummary(requestId: number): Observable<FileContentResult> {
    return this.realEstateOwnershipRequestService.getAttachmentDataForRequestSummary(requestId)
      .pipe(tap(x => downloadBlobFile(x, '', requestId.toString())));
  }
  getRealEstateRequests(input: RequestsFilterInput): Observable<PagedResultDto<RequestsList>> {
    return this.realEstateOwnershipRequestService.getRequetsList(input);
  }

  getRequestsToExcel(input: AllRequestsForExcelInput): Observable<RequestFileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequest/requetsToExcel`,
        params: {
          filter: input.filter,
          requestTypeFilter: input.requestTypeFilter,
          regionFilter: input.regionFilter,
          cityFilter: input.cityFilter,
          statusFilter: input.statusFilter,
          dateFromFilter: input.dateFromFilter,
          dateToFilter: input.dateToFilter
        },
        responseType: 'blob'
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  submitRealEstateRequest(input: RealEstateRequestSubmitInput): Observable<RequestRes> {
    return this.realEstateOwnershipRequestService.realEstateRequestSubmitByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  modifyRequestDependOnStatement(input: ModifyRequestDependOnStatementInput): Observable<any> {
    return this.realEstateOwnershipRequestService.modifyRequestDependOnStatement(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  acceptRealEstateRequest(userId: string, requestId: number): Observable<RequestRes> {
    return this.realEstateOwnershipRequestService.requestAcceptByUserNameAndRequestId(userId, requestId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  completeRequestAsInspectionMember(input: InspectionMemberNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.completeRequestAsInspectionMemberByInput(input);
  }
  completeRequestAsInspectionSupervisor(input: InspectionSupervisorNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.completeRequestAsInspectionSupervisorByInput(input);
  }
  completeRequestAsShariaMemberByInput(input: ShariaMemberNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.completeRequestAsShariaMemberByInput(input);
  }
  completeRequestAsTechnicalMemberByInput(input: TechnicalMemberNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.completeRequestAsTechnicalMemberByInput(input);
  }
  saveRequestAsInspectionMember(input: InspectionMemberNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.saveRequestAsInspectionMemberByInput(input);
  }
  saveRequestAsInspectionSupervisor(input: InspectionSupervisorNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.saveRequestAsInspectionSupervisorByInput(input);
  }
  saveRequestAsShariaMemberByInput(input: ShariaMemberNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.saveRequestAsShariaMemberByInput(input);
  }
  saveRequestAsTechnicalMemberByInput(input: TechnicalMemberNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.saveRequestAsTechnicalMemberByInput(input);
  }
  completeRequestAsSpecializationMember(input: SpecializationMemberNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.completeRequestAsSpecializationMemberByInput(input);
  }
  saveRequestAsSpecializationMember(input: SpecializationMemberNotes): Observable<void> {
    return this.realEstateOwnershipRequestService.saveRequestAsSpecializationMember(input);
  }

  getInspectionNotesByRequestId(requestId: number): Observable<InspectionMemberNotes> {
    return this.realEstateOwnershipRequestService.getInspectionNotesByRequestId(requestId);
  }
  getShariaNotesByRequestId(requestId: number): Observable<ShariaMemberNotes> {
    return this.realEstateOwnershipRequestService.getShariaNotesByRequestId(requestId);
  }
  getTechnicalNotesByRequestId(requestId: number): Observable<TechnicalMemberNotes> {
    return this.realEstateOwnershipRequestService.getTechnicalNotesByRequestId(requestId);
  }
  getSpecializationMemberNotesByRequestId(requestId: number): Observable<SpecializationMemberNotes> {
    return this.specializationMemberService.get(requestId);
  }
}
