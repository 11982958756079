import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppAuthService } from '@spga-shared/providers/auth';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  currentUser: any;
  @Input() isCreateRequestEnabled!:boolean
  @Input() isRequestLayout!:boolean
  @Output() navItemClick = new EventEmitter<boolean>();


  constructor(private authService: AppAuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentLoggedInUser$;
  }

  navigateToMyRequets(): void {
    this.onNavItemClick()
    this.router.navigate(['/', 'my-requests']);
  }

  logout(): void {
    this.authService.logout();
  }

  onNavItemClick() {
    this.navItemClick.emit(true)
  }
}
